import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  // 客户端页
  {
    path: '/client',
    name: 'Client',
    component: () => import('../views/Client.vue')
  },
  // 地图页
  {
    path: '/map',
    name: 'Map',
    component: () => import('../views/Map.vue')
  },
  // 数字商城页
  {
    path: '/digitalMall',
    name: 'DigitalMall',
    component: () => import('../views/DigitalMall.vue')
  },
  {
    path: '*',
    name: 'Home',
    component: () => import('../views/Home.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

export default router
