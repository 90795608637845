import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/style/index.css'

// 使用eventBus传值
window.eventBus = new Vue()

// 渲染函数
const render = () => {
  new Vue({
    router,
    render: (h) => h(App)
  }).$mount('#app')
}

;(async function() {
  render()
})()
